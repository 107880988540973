import { WorkspaceState } from "./../models/workspace.state";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWorkspace, setCompanyInfoData, setWorkspaceValuationCount, setWorkspaceLeadConnect } from "../slices/workspace.slice";
import workspaceService from "../services/workspace.service";
import { setUser, setUserWorkspace } from "../slices/user.slice";
import { StripeBilling, AgentSettings, ImageDetails, WorkspaceDocument, CustomerPreferences } from "@onpreo/database";
import { createFailureNotification, enqueueSnackbar } from "@onpreo/slices";
import { createNotification } from "@onpreo/slices";
import { listPipelineMails, listSegmentationMails, SaveFormThunkArgs } from "@onpreo/components";
import { trackUserPilotActivation, UserPilotEvents } from "../../utils/userpilot/userpilot-events";

export const $fetchUserWorkspaceById = createAsyncThunk("workspace/$fetchByWorkspaceId", async (workspaceId: string, thunkAPI) => {
    const response = await workspaceService.fetchByWorkspaceId(workspaceId);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

interface CreateUserWorkspaceThunk {
    workspace: any;
}

export const $createUserWorkspace = createAsyncThunk("workspace/$createUserWorkspace", async ({ workspace }: CreateUserWorkspaceThunk, thunkAPI) => {
    const response = await workspaceService.createUserWorkspace(workspace);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

export interface UpdateWorkspaceIdThunk {
    workspaceId: string;
    company?: {
        company: string;
        address: {
            street: string;
            house_number: string;
            zip: string;
            town: string;
            nation: string;
        };
    };
}

export const $createWorkspaceCustomer = createAsyncThunk(
    "workspace/$createWorkspaceCustomer",
    async ({ workspaceId, company }: UpdateWorkspaceIdThunk, thunkAPI) => {
        const response = await workspaceService.createWorkspaceCustomer({ workspaceId, company });
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateWorkspaceCustomer = createAsyncThunk(
    "workspace/$updateWorkspaceCustomer",
    async ({ workspaceId }: UpdateWorkspaceIdThunk, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceCustomer({ workspaceId });
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

type CreateWorkspaceSubscription = { workspaceId: string; price: string };

export const $createWorkspaceSubscription = createAsyncThunk(
    "workspace/$createWorkspaceSubscription",
    async ({ workspaceId, price }: CreateWorkspaceSubscription, thunkAPI) => {
        const response = await workspaceService.createWorkspaceSubscription(workspaceId, price);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(setUser(response.data.user));
        return response.data;
    }
);

type WorkspaceBilling = Pick<WorkspaceState, "billing">;

interface UpdateUserWorkspaceThunk {
    id: string;
    value: Partial<StripeBilling>;
}

export const $updateWorkspaceBilling = createAsyncThunk("workspace/$updateWorkspaceBilling", async ({ id, value }: SaveFormThunkArgs<any>, thunkAPI) => {
    const response = await workspaceService.updateWorkspaceBilling(id as string, value);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

// ===================== Workspace Company, Contact and Publication
export const $uploadWorkspaceCompanyInfo = createAsyncThunk(
    "workspace/$uploadWorkspaceCompanyInfo",
    async ({ id, value }: SaveFormThunkArgs<any>, thunkAPI) => {
        const response = await workspaceService.uploadWorkspaceCompanyInfo({ id, value });
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $uploadAgbImage = createAsyncThunk(
    "workspace/$uploadAgbImage",
    async ({ id, file, fileName, type }: { id: string; file: Blob; fileName?: string; type?: string }, thunkAPI) => {
        const response = await workspaceService.uploadAgbImage(id, file, fileName, type);
        // thunkAPI.dispatch(setWorkspace(response.data.workspace));

        thunkAPI.dispatch(setCompanyInfoData({ [type]: response.data[type] }));
        return response.data;
    }
);

export const $removeAgbWb = createAsyncThunk(
    "workspace/$removeAgb",
    async ({ id, details, type }: { id: string; details: ImageDetails; type: string }, thunkAPI) => {
        const response = await workspaceService.removeAgbWb(id, details, type);
        // thunkAPI.dispatch(setWorkspace(response.data.workspace));

        thunkAPI.dispatch(setCompanyInfoData({ [type]: undefined }));
        return response.data;
    }
);

export const $uploadWorkspaceContactInfo = createAsyncThunk("workspace/$uploadWorkspaceContactInfo", async (payload: any, thunkAPI) => {
    const response = await workspaceService.uploadWorkspaceContactInfo(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

type WorkspacePublication = {
    salutation: string;
    firstName: string;
    lastName: string;
    role: string;
    email: string;
    phone: string;
    mobile: string;
    slogan: string;
    primary: string;
    secondary: string;
};

export const $uploadWorkspacePublication = createAsyncThunk(
    "workspace/$uploadWorkspacePublication",
    async ({ id, value }: SaveFormThunkArgs<WorkspacePublication>, thunkAPI) => {
        const response = await workspaceService.uploadWorkspacePublication(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $uploadWorkspaceContents = createAsyncThunk("workspace/$uploadWorkspaceContents", async (payload: any, thunkAPI) => {
    const response = await workspaceService.uploadWorkspaceContents(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Inhalt erfolgreich gebucht", "success")));
    return response.data;
});

export const $uploadWorkspaceAddons = createAsyncThunk("workspace/$uploadWorkspaceAddons", async (payload: any, thunkAPI) => {
    const response = await workspaceService.uploadWorkspaceAddons(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $updateGeneralCustomServices = createAsyncThunk("workspace/$updateGeneralCustomServices", async (payload: any, thunkAPI) => {
    const response = await workspaceService.updateGeneralCustomServices(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

type WorkspaceLandingPage = {
    soldRealEstate: number;
    yearsExperience: number;
    customerSatisfaction: number;
    aboutUs: string;
    reviews: object;
};

export const $uploadWorkspaceLandingPage = createAsyncThunk(
    "workspace/$uploadWorkspaceLandingPage",
    async ({ id, value }: SaveFormThunkArgs<WorkspaceLandingPage>, thunkAPI) => {
        const response = await workspaceService.uploadWorkspaceLandingPage(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        // thunkAPI.dispatch(setRealEstateById({ id, value: response.data.realEstate }));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $addLandingPageProofLogo = createAsyncThunk("workspace/$addLandingPageProofLogo", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addLandingPageProofLogo({ workspaceId, data });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich hochgeladen", "success")));
    return response.data;
});

export const $deleteLandingPageProofLogo = createAsyncThunk("workspace/$deleteLandingPageProofLogo", async ({ workspaceId, index }: any, thunkAPI) => {
    const response = await workspaceService.deleteLandingPageProofLogo({ workspaceId, index });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addPublicationImage = createAsyncThunk("workspace/$addPublicationImage", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addPublicationImage({ workspaceId, data });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich hochgeladen", "success")));
    return response.data;
});

export const $deletePublicationImage = createAsyncThunk("workspace/$deletePublicationImage", async ({ workspaceId, index }: any, thunkAPI) => {
    const response = await workspaceService.deletePublicationImage({ workspaceId, index });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addLandingPageRealEstates = createAsyncThunk("workspace/$addLandingPageRealEstates", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addLandingPageRealEstates({ workspaceId, data });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addLandingPageRealEstatesImage = createAsyncThunk(
    "workspace/$addLandingPageRealEstatesImage",
    async ({ workspaceId, reId, data }: any, thunkAPI) => {
        const response = await workspaceService.addLandingPageRealEstatesImage({ workspaceId, reId, data });
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $deleteLandingPageRealEstate = createAsyncThunk("workspace/$deleteLandingPageRealEstate", async ({ workspaceId, reId }: any, thunkAPI) => {
    const response = await workspaceService.deleteLandingPageRealEstate({ workspaceId, reId });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

// ===================== Agent Settings Thunks

export type UploadAgentImagePayload = {
    workspaceId: string;
    data: { png: Blob; fileName: string };
};

export const $uploadAgentImage = createAsyncThunk("workspace/$uploadAgentImage", async (payload: UploadAgentImagePayload, thunkAPI) => {
    const response = await workspaceService.uploadAgentImage(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export type UpdateAgentSettingsPayload = {
    workspaceId: string;
    settings: AgentSettings;
};

export const $updateAgentSettings = createAsyncThunk("workspace/$updateAgentSettings", async (payload: UpdateAgentSettingsPayload, thunkAPI) => {
    const response = await workspaceService.updateAgentSettings(payload.settings, payload.workspaceId);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Maklerprofil wurde erfolgreich gespeichert", "success")));
    await trackUserPilotActivation(UserPilotEvents.GUEST_DASHBOARD.SETUP_BROKER_PROFILE);
    return response.data;
});

export const $addTeamUser = createAsyncThunk("workspace/$addTeamUser", async ({ workspaceId, newUser }: any, thunkAPI) => {
    const response = await workspaceService.addTeamUser({ workspaceId, data: newUser });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $removeTeamUser = createAsyncThunk("workspace/$removeTeamUser", async ({ workspaceId, userId, value }: any, thunkAPI) => {
    const response = await workspaceService.removeTeamUser({ workspaceId, userId, value });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addLogo = createAsyncThunk("workspace/$addLogo", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addLogo({ workspaceId, data });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(setUserWorkspace({ workspace: response.data.workspace }));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich hochgeladen", "success")));
    return response.data;
});

export const $removeLogo = createAsyncThunk("workspace/$removeLogo", async ({ workspaceId }: any, thunkAPI) => {
    const response = await workspaceService.removeLogo({ workspaceId });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich gelöscht", "success")));
    return response.data;
});

export const $updateWorkspaceExtras = createAsyncThunk("workspace/$updateWorkspaceExtras", async (payload: any, thunkAPI) => {
    const response = await workspaceService.updateWorkspaceExtras(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    if (!payload?.data?.filteredTags) thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert ", "success")));
    return response.data;
});

export const $updateWorkspaceIntegrations = createAsyncThunk("workspace/$updateWorkspaceIntegrations", async (payload: any, thunkAPI) => {
    const response = await workspaceService.updateWorkspaceIntegrations(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $updateTags = createAsyncThunk("workspace/$updateTags", async ({ id, value }: { id: string; value: any }, thunkAPI) => {
    const response = await workspaceService.updateTags(id, value);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Tag erfolgreich hinzugefügt", "success")));
    return response.data;
});

export const $updateCustomerPreferences = createAsyncThunk(
    "workspace/$updateCustomerPreferences",
    async ({ id, value }: SaveFormThunkArgs<CustomerPreferences>, thunkAPI) => {
        const response = await workspaceService.updateCustomerPreferences(id, value);
        console.log(response.data.workspace);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        // thunkAPI.dispatch(enqueueSnackbar(createNotification("Präferenzen angepasst", "success")));
        return response.data;
    }
);

export const $updateBackground = createAsyncThunk("workspace/$updateBackground", async ({ id, value }: SaveFormThunkArgs<WorkspaceDocument>, thunkAPI) => {
    const response = await workspaceService.updateBackground(id, value);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $addBackgroundImage = createAsyncThunk(
    "workspace/$addackgroundImage",
    async ({ id, file, fileName, isSecure }: { id: string; file: Blob; fileName?: string; isSecure?: boolean }, thunkAPI) => {
        const response = await workspaceService.addBackgroundImage(id, file, fileName, isSecure);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich hochgeladen", "success")));
        return response.data;
    }
);

export const $removeBackgroundImage = createAsyncThunk(
    "workspace/$removeBackgroundImage",
    async ({ id, details, isSecure }: { id: string; details: ImageDetails; isSecure?: boolean }, thunkAPI) => {
        try {
            const response = await workspaceService.removeBackgroundImage(id, details, isSecure);
            thunkAPI.dispatch(setWorkspace(response.data.workspace));

            return true;
        } catch (err) {
            return false;
        }
    }
);

export const $uploadContent = createAsyncThunk(
    "workspace/$uploadContent",
    async (
        {
            id,
            file,
            fileName,
            isSecure,
            contentId,
            contentType
        }: { id: string; file: Blob; fileName?: string; isSecure?: boolean; contentId?: string; contentType?: string },
        thunkAPI
    ) => {
        const response = await workspaceService.uploadContent(id, file, fileName, isSecure, contentId, contentType);
        return response.data;
    }
);

export const $createMockups = createAsyncThunk("$createMockups", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    const response = await workspaceService.createMockups(workspaceId);
    return response.data;
});

export const $activateEmailTemplate = createAsyncThunk(
    "workspace/$activateEmailTemplate",
    async ({ workspaceId, emailId, isActivated }: { workspaceId: string; emailId: string; isActivated: boolean }, thunkAPI) => {
        const response = await workspaceService.activateEmailTemplate(workspaceId, emailId, isActivated);

        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));

        if (isActivated && listPipelineMails.includes(+emailId))
            await trackUserPilotActivation(UserPilotEvents.ACQUISITION_PIPELINE.ENABLE_SETTING_PIPELINES_EMAILS);
        if (isActivated && listSegmentationMails.includes(+emailId))
            await trackUserPilotActivation(UserPilotEvents.ACQUISITION_PIPELINE.ACTIVATE_SEGMENTATION_EMAILS);

        return response.data;
    }
);

export const $countWorkspaceValuations = createAsyncThunk("workspace/$countWorkspaceValuations", async (_, thunkAPI) => {
    const response = await workspaceService.countWorkspaceValuations();
    thunkAPI.dispatch(setWorkspaceValuationCount({ count: response.data.count }));
    // thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $saveWorkspaceSubscriptionPrice = createAsyncThunk(
    "workspace/$createWorkspaceSubscription",
    async ({ workspaceId, price }: CreateWorkspaceSubscription, thunkAPI) => {
        const response = await workspaceService.saveWorkspaceSubscriptionPrice(workspaceId, price);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateWorkspaceInvitationLink = createAsyncThunk(
    "workspace/$updateWorkspaceInvitationLink",
    async ({ workspaceId, link }: { workspaceId: string; link: boolean }, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceInvitationLink(workspaceId, link);
        // thunkAPI.dispatch(setWorkspace(response.data.workspace));
        // thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $uploadImageToLibrary = createAsyncThunk("workspace/$uploadImageToLibrary", async ({ id, file }: { id: string; file: Blob }, thunkAPI) => {
    const response = await workspaceService.uploadImageToLibrary(id, file);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));

    return response.data;
});

export const $removeImageFromLibrary = createAsyncThunk(
    "workspace/$removeImageFromLibrary",
    async ({ id, src, removeId }: { id: string; src: string; removeId: string }, thunkAPI) => {
        const response = await workspaceService.removeImageFromLibrary(id, removeId, src);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $createLocationDraft = createAsyncThunk(
    "workspace/$createLocationDraft",
    async ({ id, data }: { id: string; data: { title: string; description: string } }, thunkAPI) => {
        const response = await workspaceService.createLocationDraft(id, data);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $deleteLocationDraft = createAsyncThunk(
    "workspace/$deleteLocationDraft",
    async ({ id, draftId }: { id: string; draftId: string }, thunkAPI) => {
        const response = await workspaceService.deleteLocationDraft(id, draftId);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $uploadAgentSettingsDocs = createAsyncThunk(
    "workspace/$uploadAgentSettingsDocs",
    async ({ id, file, fileName }: { id: string; file: Blob; fileName?: string }, thunkAPI) => {
        const response = await workspaceService.uploadAgentSettingsDocs(id, file, fileName);
        // thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $removeAgentSettingsDocs = createAsyncThunk(
    "workspace/$removeAgentSettingsDocs",
    async ({ id, details }: { id: string; details: ImageDetails }, thunkAPI) => {
        const response = await workspaceService.removeAgentSettingsDocs(id, details);
        // thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateCRMIntegrations = createAsyncThunk("workspace/$updateCRMIntegrations", async ({ id, value }: { id: string; value: any }, thunkAPI) => {
    const response = await workspaceService.updateCRMIntegrations(id, value);
    return response.data;
});

export const $updateWorkspaceContentsResponsibleUser = createAsyncThunk(
    "workspace/$updateWorkspaceContentsResponsibleUser",
    async ({ workspaceId, value }: { workspaceId: string; value: any }, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceContentsResponsibleUser(workspaceId, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    }
);

export const $updateGlobalAdjustmentFactor = createAsyncThunk(
    "workspace/$updateGlobalAdjustmentFactor",
    async ({ workspaceId, globalPriceAdjustmentFactor }: { workspaceId: string; globalPriceAdjustmentFactor: any }, thunkAPI) => {
        const response = await workspaceService.updateGlobalAdjustmentFactor(workspaceId, globalPriceAdjustmentFactor);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateGlobalRentalAdjustmentFactor = createAsyncThunk(
    "workspace/$updateGlobalRentalAdjustmentFactor",
    async ({ workspaceId, globalRentalPriceAdjustmentFactor }: { workspaceId: string; globalRentalPriceAdjustmentFactor: any }, thunkAPI) => {
        const response = await workspaceService.updateGlobalRentalAdjustmentFactor(workspaceId, globalRentalPriceAdjustmentFactor);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $deleteCRMIntegrations = createAsyncThunk(
    "integrations/$deleteCRMIntegrations",
    async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
        const response = await workspaceService.deleteCRMIntegrations(workspaceId);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateStepMailInMailCenter = createAsyncThunk(
    "workspace/$updateStepMailInMailCenter",
    async (
        {
            id,
            value
        }: { id: string; value: { step: string; tag: string; templateId: string; hasDefault?: boolean; subject?: string; mailDesigner?: boolean } },
        thunkAPI
    ) => {
        const response = await workspaceService.updateStepMailInMailCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $addStepMailsByMultipleTagsInMailCenter = createAsyncThunk(
    "workspace/$addStepMailsByMultipleTagsInMailCenter",
    async (
        {
            id,
            value
        }: {
            id: string;
            value: {
                step: string;
                templateId: string;
                subject?: string;
                currentValues: { tag: string; hasDefault: boolean };
                connectedId: string;
                mailDesigner?: boolean;
            };
        },
        thunkAPI
    ) => {
        const response = await workspaceService.addStepMailsByMultipleTagsInMailCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateStepMailsByMultipleTagsInMailCenter = createAsyncThunk(
    "workspace/$updateStepMailsByMultipleTagsInMailCenter",
    async (
        {
            id,
            value,
            connectedId
        }: {
            id: string;
            value: {
                step: string;
                templateId: string;
                subject?: string;
                currentValues: { tag: string; hasDefault: boolean };
                connectedId: string;
                mailDesigner?: boolean;
            };
            connectedId: string;
        },
        thunkAPI
    ) => {
        const response = await workspaceService.updateStepMailsByMultipleTagsInMailCenter(id, value, connectedId);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $deleteMailFromMailCenter = createAsyncThunk(
    "workspace/$deleteMailFromMailCenter",
    async ({ id, value }: { id: string; value: { step: string; tag: string; hasDefault: boolean } }, thunkAPI) => {
        const response = await workspaceService.deleteMailFromMailCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $deleteMailFromMailCenterWithMultipleTags = createAsyncThunk(
    "workspace/$deleteMailFromMailCenterWithMultipleTags",
    async ({ id, value }: { id: string; value: { step: string; currentValues: { tag: string; hasDefault: boolean }; connectedId: string } }, thunkAPI) => {
        const response = await workspaceService.deleteMailFromMailCenterWithMultipleTags(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateUseDefaultInMailCenter = createAsyncThunk(
    "workspace/$updateUseDefaultInMailCenter",
    async ({ id, value }: { id: string; value: { step: string; tag: string; useDefault: boolean } }, thunkAPI) => {
        const response = await workspaceService.updateUseDefaultInMailCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateStepActivityInActivityCenter = createAsyncThunk(
    "workspace/$updateStepActivityInActivityCenter",
    async ({ id, value }: { id: string; value: { step: string; templateIds: string[] } }, thunkAPI) => {
        const response = await workspaceService.updateStepActivityInActivityCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateDefaultIdsInActivityCenter = createAsyncThunk(
    "workspace/$updateDefaultIdsInActivityCenter",
    async ({ id, data }: { id: string; data: { step: string; defaultId: string; value: boolean } }, thunkAPI) => {
        const response = await workspaceService.updateDefaultIdsInActivityCenter(id, data);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $deleteActivityTemplateFromActivityCenter = createAsyncThunk(
    "workspace/$deleteActivityTemplateFromActivityCenter",
    async ({ id, value }: { id: string; value: { step: string; templateId: string } }, thunkAPI) => {
        const response = await workspaceService.deleteActivityTemplateFromActivityCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateGlobalSegmentationMails = createAsyncThunk(
    "workspace/$updateGlobalSegmentationMails",
    async ({ workspaceId, isActivated }: { workspaceId: string; isActivated: boolean }, thunkAPI) => {
        const response = await workspaceService.updateGlobalSegmentationMails(workspaceId, isActivated);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateVisibilityInPipelineCenter = createAsyncThunk(
    "workspace/$updateVisibilityInPipelineCenter",
    async ({ id, value }: { id: string; value: any }, thunkAPI) => {
        const response = await workspaceService.updateVisibilityInPipelineCenter(id, value);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateRealEstateLPsRedirect = createAsyncThunk(
    "workspace/$updateRealEstateLPsRedirect",
    async ({ workspaceId, redirect }: { workspaceId: string; redirect: boolean }, thunkAPI) => {
        const response = await workspaceService.updateRealEstateLPsRedirect(workspaceId, redirect);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateWorkspaceIntegrationsExportSetting = createAsyncThunk(
    "workspace/$updateWorkspaceIntegrationsExportSetting",
    async ({ value, variant }: { value: boolean; variant: "contacts" | "activities" }, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceIntegrationsExportSetting(value, variant);
        // thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateWorkspaceLeadConnect = createAsyncThunk("workspace/$updateWorkspaceLeadConnect", async ({ value }: { value: any }, thunkAPI) => {
    const response = await workspaceService.updateWorkspaceLeadConnect(value);
    thunkAPI.dispatch(setWorkspaceLeadConnect(response.data.leadConnect));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $deleteWorkspaceRealEstatesContacts = createAsyncThunk(
    "workspace/$deleteWorkspaceRealEstatesContacts",
    async ({ id }: { id: string }, thunkAPI) => {
        const response = await workspaceService.deleteWorkspaceRealEstatesContacts(id);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gelöscht", "success")));
        return response.data;
    }
);

export const $updateWorkspaceMMCSettings = createAsyncThunk(
    "workspace/$updateWorkspaceMMCSettings",
    async ({ workspaceId, mmcSettings }: { workspaceId: string; mmcSettings: { senderEmail?: string; replyToEmail?: string } }, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceMMCSettings(workspaceId, mmcSettings);
        if (response.data.workspace) {
            thunkAPI.dispatch(setWorkspace(response.data.workspace));
            thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        } else if (response.data.msg) {
            thunkAPI.dispatch(enqueueSnackbar(createNotification(response.data.msg, "warning")));
        }
        return response.data;
    }
);

export const $deleteMMCSender = createAsyncThunk("workspace/$deleteMMCSender", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    const response = await workspaceService.deleteMMCSender(workspaceId);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $deleteReplyToEmail = createAsyncThunk("workspace/$deleteReplyToEmail", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    const response = await workspaceService.deleteReplyToEmail(workspaceId);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $updateSenderStatus = createAsyncThunk(
    "workspace/$updateSenderStatus",
    async ({ workspaceId, senderMail }: { workspaceId: string; senderMail: string }, thunkAPI) => {
        const response = await workspaceService.updateSenderStatus(workspaceId, senderMail);
        thunkAPI.dispatch(enqueueSnackbar(createNotification(response.data.msg, response.data.status)));
    }
);

export const $sendSenderActivationLink = createAsyncThunk(
    "workspace/$sendSenderActivationLink",
    async ({ workspaceId, link, senderMail }: { workspaceId: string; link: string; senderMail: string }, thunkAPI) => {
        const response = await workspaceService.sendSenderActivationLink(workspaceId, { link, senderMail });
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateWorkspaceContentsPipeline = createAsyncThunk(
    "workspace/$updateWorkspaceContentsPipeline",
    async ({ workspaceId, data }: { workspaceId: string; data: any }, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceContentsPipeline(workspaceId, data);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $resetWorkspaceContentsPipeline = createAsyncThunk(
    "workspace/$resetWorkspaceContentsPipeline",
    async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
        const response = await workspaceService.resetWorkspaceContentsPipeline(workspaceId);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateWorkspaceContentsZipFilter = createAsyncThunk(
    "workspace/$updateWorkspaceContentsZipFilter",
    async ({ workspaceId, data }: { workspaceId: string; data: any }, thunkAPI) => {
        const response = await workspaceService.updateWorkspaceContentsZipFilter(workspaceId, data);
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);
